<template>
  <div>
    <div v-if="allReasons.length > 0">
      <b-table :data="allReasons" :bordered="true" :striped="true" :narrowed="false" :hoverable="false" :loading="false"
        :focusable="false" :mobile-cards="true" paginated>
        <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="title" label="Título" v-slot="props">
          {{ props.row.title }}
        </b-table-column>

        <b-table-column field="redirectTypeTitle" label="Redirecimento com contato futuro" v-slot="props">
          {{ props.row.redirectTypeTitle }}
        </b-table-column>

        <b-table-column field="satisfactionSurvey" label="Pesquisa de satisfação" v-slot="props">
          {{ props.row.satisfaction_survey ? props.row.satisfaction_survey.question : '' }}
        </b-table-column>

        <b-table-column field="ww_phone" label="Número do motivo" v-slot="props">
          {{ props.row.ww_phone ?? '' }}
        </b-table-column>

        <b-table-column field="require_explanation" label="Exigir explicação do atendente" v-slot="props">
          <YesNoTag :isYes="props.row.require_explanation" />
        </b-table-column>

        <b-table-column field="force_send_message" label="Forçar envio de mensagem para cliente" v-slot="props">
          <YesNoTag :isYes="props.row.force_send_message" />
        </b-table-column>

        <b-table-column field="can_edit_message" label="Permitir modificação da mensagem" v-slot="props">
          <YesNoTag :isYes="props.row.can_edit_message" />
        </b-table-column>

        <b-table-column field="is_active" label="Status" v-slot="props">
          <is-active-tag :active="props.row.enabled" />
        </b-table-column>

        <b-table-column field="update_delete" label="" v-slot="props">
          <dropdown-tag :isDeleting="reasonDeletingId === props.row.id">
            <dropdown-item-tag @click="updateReason(props.row)">Atualizar</dropdown-item-tag>
            <dropdown-item-tag @click="confirmReasonDelete(props.row)">Excluir</dropdown-item-tag>
          </dropdown-tag>
        </b-table-column>

      </b-table>
    </div>
  </div>
</template>

<script>
import YesNoTag from '../commons/YesNoTag.vue'
import IsActiveTag from '../commons/IsActiveTag.vue'
import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'

export default {
  name: 'ReasonsList',
  props: {
    reasons: {
      type: Array,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    reasonDeletingId: {
      type: Number,
      required: true
    }
  },
  components: {
    YesNoTag,
    IsActiveTag,
    DropdownTag,
    DropdownItemTag
  },
  computed: {
    allReasons: {
      get () {
        return this.reasons
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    confirmReasonDelete (reason) {
      this.$buefy.dialog.confirm({
        title: 'Excluir motivo de fechamento de ticket',
        message: 'Deseja <b>excluir</b> esta motivo de fechamento de ticket?',
        confirmText: 'Excluir motivo de fechamento de ticket',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeReason', reason)
      })
    },
    updateReason (reason) {
      this.$emit('updateReason', reason)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
