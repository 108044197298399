<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">Motivos de fechamento de tickets</hero-bar>
    <section class="section is-main-section">
      <ModalInsertReason
        v-if="isComponentModalActive"
        v-on:addReason="handleAddReason"
        v-on:closeModal="closeModal"
        v-on:updateReason="handleUpdateReason"
        :isComponentModalActive="isComponentModalActive"
        :reasonUpdating="reasonUpdating"
        :surveys="allSurveysEnabled"
      />
      <b-loading
        v-if="fetchingReasons"
        :is-full-page="true"
        v-model="fetchingReasons"
        :can-cancel="false"
      ></b-loading>
      <ReasonsList
        v-if="allReasons && !fetchingReasons"
        :reasons="allReasons"
        :isAdmin="getIsAdmin"
        v-on:removeReason="deleteReason"
        v-on:updateReason="openUpdateReason"
        :reasonDeletingId="reasonDeletingId"
      />
      <span v-if="allReasons.length === 0 && !fetchingReasons">Não foram encontratos motivos de fechamento de tickets cadastrados</span>
      <FabButton
        v-on:addClick="handleAddClick"
        iconClass="fas fa-pen-fancy"
        title="Novo motivo"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import ReasonsList from '@/components/TicketsCloseReason/ReasonsList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalInsertReason from '@/components/modals/ModalInsertReason'

export default {
  name: 'TicketsCloseReason',
  components: {
    HeroBar,
    TitleBar,
    ReasonsList,
    FabButton,
    ModalInsertReason
  },
  data () {
    return {
      isComponentModalActive: false,
      reasonUpdating: null,
      reasonDeletingId: 0
    }
  },
  computed: {
    ...mapGetters(['allReasons', 'fetchingReasons', 'getIsAdmin', 'allSurveysEnabled']),
    titleStack () {
      return ['Cadastros', 'Motivos de fechamento de tickets']
    }
  },
  methods: {
    ...mapActions([
      'addReason',
      'fetchReasons',
      'fetchSatisfactionSurveys',
      'removeReason',
      'updateReason'
    ]),
    handleAddReason (reason) {
      this.closeModal()
      this.addReason(reason)
    },
    handleAddClick () {
      this.fetchSatisfactionSurveys()
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.reasonUpdating = null
      this.isComponentModalActive = false
    },
    async deleteReason (reason) {
      try {
        this.reasonDeletingId = reason.id
        await this.removeReason(reason.id)
        this.$buefy.toast.open('Motivo excluido com sucesso!')
      } catch (error) {
        this.$buefy.toast.open(
          'Não é possível excluir este motivo, possivelmente ele possui relacionamentos com tickets!'
        )
      } finally {
        this.reasonDeletingId = 0
      }
    },
    openUpdateReason (reason) {
      this.reasonUpdating = reason
      this.handleAddClick()
    },
    handleUpdateReason (reason) {
      this.closeModal()
      this.updateReason(reason).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message:
            'Ocorreu um <b>erro</b> ao atualizar o motivo. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    }
  },
  async created () {
    this.fetchReasons()
    await this.fetchSatisfactionSurveys()
  }
}
</script>
