<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="reasonUpdating" class="modal-card-title">Atualizar informações do motivo de fechamento</p>
            <p v-else class="modal-card-title">Insira o motivo de fechamento a ser inserido</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Número do motivo">
              <b-select expanded placeholder="Número do motivo" v-model="ww_phone">
                <option :value="null" :key="null">
                </option>
                <option v-for="phone in allPhonesEnabled" :value="phone.phone" :key="phone.phone">
                  {{ phone.phone }} {{ phone.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Descrição do motivo*">
              <b-input type="text" v-model="title" placeholder="Descrição do motivo">
              </b-input>
            </b-field>
            <b-field label="Tipo*">
              <b-select placeholder="Selecione uma opção" v-model="reason_type" expanded>
                <option value="FIN" key="FIN">Atendimento finalizado com sucesso</option>
                <option value="FIE" key="FIE">Atendimento finalizado com problemas</option>
                <option value="PEN" key="PEN">Atendimento pendente de reavaliação futura</option>
                <option value="NUR" key="NUR">Atendimento sem resposta do cliente</option>
              </b-select>
            </b-field>
            <b-field label="O que fazer caso o cliente entre em contato novamente*">
              <b-select placeholder="Selecione uma opção" v-model="redirect_type" expanded>
                <option value="NRE" key="NRE">Seguir o fluxo normal de atendimento</option>
                <option value="REA" key="REA">Redirecionar para o último atendente selecionado</option>
                <option value="RED" key="RED">Redirecionar para o último departamento selecionado</option>
              </b-select>
            </b-field>
            <b-field label="Pesquisa de satisfação">
              <b-select placeholder="Selecione uma pesquisa" v-model="surveySelected" expanded>
                <option :value="null"></option>
                <option v-for="survey in surveys" :value="survey.id" :key="survey.id">
                  {{ survey.question }}
                </option>
              </b-select>
            </b-field>
            <div class="field">
              <b-checkbox v-model="require_explanation">Exigir explicação do atendente</b-checkbox>
            </div>
            <div class="field">
              <b-checkbox v-model="force_send_message">Forçar envio de mensagem de encerramento de ticket</b-checkbox>
            </div>
            <div class="field">
              <b-checkbox v-model="can_edit_message">Permitir que o atendente modifique a mensagem de
                encerramento</b-checkbox>
            </div>
            <b-field label="Mensagem customizada a ser exibida ao cliente (sobrepõe a mensagem geral configurada)">
              <editor api-key="no-api-key" v-model="custom_message" output-format="text" :init="{
                height: 200,
                menubar: false,
                plugins: [
                  'emoticons'
                ],
                toolbar:
                  'emoticons',
                branding: false,
                statusbar: false,
              }" />
            </b-field>
            <div class="field">
              <b-checkbox v-model="enabled">Motivo ativo</b-checkbox>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Fechar</button>
            <button v-if="!!reasonUpdating" class="button is-primary" @click="updateReason">Atualizar</button>
            <button v-else class="button is-primary" @click="addReason">Gravar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import Editor from '@tinymce/tinymce-vue'
import { mapGetters, mapActions } from 'vuex'
import multiselect from 'vue-multiselect'

export default {
  name: 'ModalInsertReason',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    reasonUpdating: {
      type: Object,
      required: false
    },
    surveys: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor,
    multiselect
  },
  created () {
    this.fetchSettings();

    if (this.reasonUpdating) {
      this.title = this.reasonUpdating.title
      this.force_send_message = this.reasonUpdating.force_send_message
      this.custom_message = this.reasonUpdating.custom_message
      this.redirect_type = this.reasonUpdating.redirect_type
      this.require_explanation = this.reasonUpdating.require_explanation
      this.reason_type = this.reasonUpdating.reason_type
      this.enabled = this.reasonUpdating.enabled
      this.can_edit_message = this.reasonUpdating.can_edit_message
      this.surveySelected = this.reasonUpdating.satisfaction_survey_id
      this.ww_phone = this.reasonUpdating.ww_phone
    }
  },
  data() {
    return {
      title: '',
      force_send_message: false,
      custom_message: '',
      redirect_type: '',
      require_explanation: false,
      reason_type: '',
      enabled: true,
      can_edit_message: true,
      surveySelected: null,
      ww_phone: null
    }
  },
  computed: {
    ...mapGetters(['allPhonesEnabled'])
  },
  methods: {
    ...mapActions(['fetchReasons', 'fetchSettings']),
    addReason() {
      this.$emit('addReason', {
        title: this.title,
        force_send_message: this.force_send_message,
        custom_message: this.custom_message,
        redirect_type: this.redirect_type,
        require_explanation: this.require_explanation,
        reason_type: this.reason_type,
        enabled: this.enabled,
        can_edit_message: this.can_edit_message,
        satisfaction_survey_id: this.surveySelected,
        ww_phone: this.ww_phone
      })
    },
    closeModal() {
      this.$emit('closeModal')
    },
    phoneUpdateHandler(phoneObj) {
      this.countryCode = phoneObj.countryCallingCode
      this.validPhone = phoneObj.nationalNumber
    },
    updateReason() {
      if (this.name && this.name.length >= 0 && this.name.length <= 2) {
        this.$buefy.dialog.alert({
          title: 'Nome muito curto',
          message: 'O <b>nome</b> informado é inválido. O novo nome deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.emitUpdateReason({
        id: this.reasonUpdating.id,
        title: this.title,
        force_send_message: this.force_send_message,
        custom_message: this.custom_message,
        redirect_type: this.redirect_type,
        require_explanation: this.require_explanation,
        reason_type: this.reason_type,
        enabled: this.enabled,
        can_edit_message: this.can_edit_message,
        satisfaction_survey_id: this.surveySelected,
        ww_phone: this.ww_phone
      })
    },
    emitUpdateReason(reason) {
      this.$emit('updateReason', reason)
    }
  }
}
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
