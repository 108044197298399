<template>
  <div class="yesNo-container">
    <span class="tag is-success" v-if="isYes">
      Sim
    </span><span class="tag no" v-else>
      Não
    </span>
  </div>
</template>

<script>
export default {
  name: 'YesNoTag',
  props: {
    isYes: {
      type: Boolean,
      require: true
    }
  }
}
</script>

<style >
.no {
  background-color: rgb(205, 204, 204) !important;
  color: #FFFFFF !important;
}

.table td {
  height: 100%;
  position: relative;
}

.yesNo-container{
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

</style>
